<template>
  <CampaignResults />
</template>

<script>
import CampaignResults from '@/components/pages/campaign/CampaignResults'

export default {
  components: {
    CampaignResults
  }
}
</script>
