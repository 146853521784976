<template>
  <Modal
    :header="data?.campaignName"
    :show="show"
    :loading="loadingLists"
    width="700px"
    cancelLabel="Ok"
    @close="close"
    closeOnly
  >
    <q-card flat>
      <q-card-section>
        <Table
          separator="cell"
          :rows="salesTableData"
          :columns="salesTableHeader"
          :loading="loadingLists"
          flat
          bordered
          hideBottom
          hideHeader
        >
          <template v-slot:top>
            <div>
              <h6>Tier Campaign Results</h6>
              <div class="text-grey-8 text-xs">Unique Customer Count</div>
            </div>
          </template>
        </Table>
      </q-card-section>
    </q-card>
  </Modal>
</template>

<script>
import { ref, watch, onBeforeMount, watchEffect } from 'vue'
import Modal from '@/components/Modal'
import Table from '@/components/Table'

import { Toast, fetchData } from '@/tools'

export default {
  name: 'ViewCampaignDiscountModal',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
  components: { Modal, Table },
  setup(props, { emit }) {
    const { showToast } = Toast()

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const salesTableData = ref([])
    const salesTableHeader = ref([
      {
        name: 'name',
        label: 'Tier Type',
        field: 'name',
        align: 'left',
        sortable: true
      },
      {
        name: 'count',
        label: 'Unique Customer Count',
        field: 'count',
        align: 'center',
        sortable: true
      }
    ])

    const close = () => {
      emit('close')
    }

    const getTierResults = id => {
      getLists(`/v1/campaign/tier_results/${id}`)
    }

    watchEffect(() => {
      if (props.show && props?.data?.id) {
        getTierResults(props?.data?.id)
      }
    })

    onBeforeMount(() => {
      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          salesTableData.value = null
        } else {
          if (errorLists?.value) {
            showToast(
              errorLists?.value?.message ??
                'There was a problem fetching tier results.',
              'danger'
            )
          } else if (dataLists?.value) {
            const data = dataLists?.value?.campaign

            salesTableData.value = data?.map(item => ({
              name: `Php ${item?.tier ?? 0} coupon`,
              count: item?.unique_customers
            }))
          }
        }
      })
    })

    return {
      loadingLists,
      salesTableData,
      salesTableHeader,
      close
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  @apply mb-4;
  @apply flex flex-col gap-0;
  @apply md:mb-2 md:flex-row md:gap-2;

  > div {
    @apply w-36;
    @apply font-bold;
    min-width: 10rem;
  }
}
</style>
