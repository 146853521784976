<template>
  <div class="row items-center justify-between">
    <div>
      <PageHeader breadcrumb="Campaign" title="Campaign Results" />
    </div>

    <div class="q-gutter-md">
      <Button
        variant="primary"
        label="Export"
        size="lg"
        :disabled="!hasData"
        outline
        @click="exportTable"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_export')" width="30" />
        </template>
      </Button>
      <Button
        variant="primary"
        label="Help"
        size="lg"
        outline
        @click="onToggleModal"
      >
        <template v-slot:icon>
          <img :src="getIconUrl('icon_how_to')" width="30" />
        </template>
      </Button>
    </div>
  </div>

  <PageContent>
    <div class="col q-col-gutter-md">
      <div>
        <q-tabs
          v-model="tabSelected"
          class="bg-primary text-white shadow-2"
          align="justify"
          outside-arrows
          mobile-arrows
        >
          <q-tab
            v-for="tab in tabs"
            :key="tab.name"
            :name="tab.name"
            :icon="tab.icon"
            :label="tab.name"
            v-show="tab.enabled"
            @click="onToggleTab(tab.value)"
          />
        </q-tabs>
      </div>
      <div class="row items-start q-col-gutter-md">
        <div class="col-xs-12 col-sm-12 col-md-12">
          <Table
            :rows="salesTableData"
            :columns="salesTableHeader"
            :loading="loadingLists"
            :limit="limitPage"
            :page="pageNo"
            :total="totalCount"
            :filter="search"
            @onRequest="onRequest"
          >
            <template v-slot:top-left>
              <h6>
                <p class="text-grey-6" v-if="search">
                  <small> Search result for: "{{ search }}" </small>
                </p>
              </h6>
            </template>

            <template v-slot:top-right>
              <q-input
                dense
                rounded
                outlined
                debounce="300"
                v-model="search"
                placeholder="Search"
              >
                <template v-slot:append>
                  <q-icon
                    :name="search ? 'close' : 'search'"
                    :class="search && 'cursor-pointer'"
                    @click="onClearSearch"
                  />
                </template>
              </q-input>
            </template>

            <template v-slot:body-cell-campaignCode="props">
              <q-td :props="props">
                <div class="multiLineLabel">
                  <span class="textMaxLine">
                    {{ props.row.campaignCode }}
                  </span>
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-campaignName="props">
              <q-td :props="props">
                <div class="multiLineLabel">
                  <span class="textMaxLine">
                    {{ props.row.campaignName }}
                  </span>
                </div>

                <div
                  class="text-primary cursor-pointer hover:underline"
                  @click="onOpenViewDiscountModal(props.row)"
                  v-if="props.row.tierTag"
                >
                  <q-icon name="sell" /> View tier campaign results
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-reach="props">
              <q-td :props="props">
                <div class="text-subtitle1">
                  {{ FORMAT.toNumber(props.row.reach) }}
                </div>
              </q-td>
            </template>

            <template v-slot:body-cell-claimed="props">
              <q-td :props="props">
                <div class="text-subtitle1">
                  {{ FORMAT.toNumber(props.row.claimed) }}
                </div>
                <small class="text-darkgray">
                  ( {{ FORMAT.toPercent(props.row.percentClaimed) }} )
                </small>
              </q-td>
            </template>

            <template v-slot:body-cell-unclaimed="props">
              <q-td :props="props">
                <div class="text-subtitle1">
                  {{ FORMAT.toNumber(props.row.unclaimed) }}
                </div>
                <small class="text-darkgray">
                  ( {{ FORMAT.toPercent(props.row.percentUnclaimed) }} )
                </small>
              </q-td>
            </template>

            <template v-slot:body-cell-status="props">
              <q-td :props="props">
                <Badge
                  :variant="getStatusData(props.row.status)?.color"
                  :text="getStatusData(props.row.status)?.label"
                />
              </q-td>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </PageContent>

  <Modal
    :show="showModal"
    :footer="false"
    header="Help"
    width="700px"
    cancelLabel="Close"
    @close="onToggleModal"
    closeOnly
  >
    <div class="p-4">
      <h5 class="q-mb-md">Campaign Results</h5>
      <p>
        {{ HELP.CAMPAIGN_RESULTS.description }}
      </p>

      <q-separator class="q-my-md" />

      <Table
        :rows="HELP.CAMPAIGN_RESULTS.lists"
        :columns="HELP.TABLE"
        :limit="0"
        hide-bottom
      >
        <template v-slot:body="props">
          <q-tr>
            <q-td>
              <strong>{{ props.row.name }}</strong>
            </q-td>
            <q-td :style="{ whiteSpace: 'normal' }">
              {{ props.row.description }}
            </q-td>
          </q-tr>
        </template>
      </Table>
    </div>
  </Modal>

  <ViewCampaignDiscountModal
    :show="showViewDiscountModal"
    :data="selectedData"
    @close="onCloseViewDiscountModal"
  />
</template>

<script>
import { ref, watch, onBeforeMount, computed } from 'vue'
import { useRouter } from 'vue-router'

import PageHeader from '@/components/PageHeader'
import PageContent from '@/components/PageContent'
import Button from '@/components/Button'
import Table from '@/components/Table'
import Modal from '@/components/Modal'
import Badge from '@/components/Badge'

import ViewCampaignDiscountModal from './components/ViewCampaignDiscountModal'

import store from '@/store'
import { HELP, campaign, campaignType } from '@/constants'
import {
  FORMAT,
  VALIDATION,
  DATE,
  getIconUrl,
  fetchData,
  EXPORT
} from '@/tools'

export default {
  name: 'CampaignResults',
  components: {
    PageHeader,
    PageContent,
    Button,
    Table,
    Modal,
    Badge,
    ViewCampaignDiscountModal
  },
  setup() {
    const router = useRouter()
    const campaignTab = router?.currentRoute?.value?.params?.tab ?? 'all'

    const {
      data: dataLists,
      error: errorLists,
      loading: loadingLists,
      get: getLists
    } = fetchData()

    const tabs = ref(campaign.filter(item => item.tab === true))
    const tabSelected = ref(campaignTab)
    const tabID = ref(
      campaign.filter(item => item.name === tabSelected.value)[0].value
    )
    const showModal = ref(false)
    const showViewDiscountModal = ref(false)
    const selectedData = ref(null)
    const isFiltered = ref(false)
    const limitPage = ref(10)
    const pageNo = ref(1)
    const totalCount = ref(0)
    const search = ref('')
    const filterForm = ref(null)
    const campaignStatus = ref(null)
    const tierTag = ref(false)
    const salesTableData = ref([])
    const salesTableHeader = ref([
      {
        name: 'campaignCode',
        label: 'Campaign Code',
        field: 'campaignCode',
        align: 'left',
        sortable: true,
        style: 'max-width: 200px',
        headerStyle: 'max-width: 200px'
      },
      {
        name: 'campaignName',
        label: 'Campaign Name',
        field: 'campaignName',
        align: 'left',
        sortable: true,
        style: 'max-width: 300px',
        headerStyle: 'max-width: 300px'
      },
      {
        name: 'campaignDuration',
        label: 'Campaign Duration',
        field: 'campaignDuration',
        align: 'center',
        sortable: true
      },
      {
        name: 'status',
        label: 'Status',
        field: 'status',
        align: 'center',
        sortable: true
      },
      {
        name: 'sales',
        label: 'Sales',
        field: 'sales',
        format: val => FORMAT.toCurrency(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'trxCount',
        label: 'Trans Count',
        field: 'trxCount',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'basketSize',
        label: 'Basket Size',
        field: 'basketSize',
        format: val => FORMAT.toCurrency(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'reach',
        label: 'Reach',
        field: 'reach',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'claimed',
        label: 'Claimed',
        field: 'claimed',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      },
      {
        name: 'unclaimed',
        label: 'Unclaimed',
        field: 'unclaimed',
        format: val => FORMAT.toNumber(val),
        align: 'center',
        sortable: true
      }
    ])

    const hasData = computed(
      () => salesTableData.value && salesTableData.value.length > 0
    )

    const statusOptions = computed(() =>
      tabs.value
        .filter(item => item.enabled === true)
        .map(item => ({
          label: item.label,
          value: item.value
        }))
    )

    const onToggleTab = data => {
      const campaignTabName = campaign.filter(item => item.value === data)[0]
        .name

      tabID.value = data
      store.methods.updatePreviousTab(campaignTabName)
      history.pushState({}, null, `/campaign/results/${campaignTabName}`)
      getCampaignLists()
    }

    const onToggleModal = () => {
      showModal.value = !showModal.value
    }

    const onOpenViewDiscountModal = data => {
      showViewDiscountModal.value = true
      selectedData.value = data
    }

    const onCloseViewDiscountModal = () => {
      showViewDiscountModal.value = false
      selectedData.value = null
    }

    const getStatusData = data => {
      return campaign.filter(item => item.label === data)[0]
    }

    const onClearSearch = () => {
      search.value = ''
    }

    const exportTable = () => {
      EXPORT.exportToExcel({
        header: salesTableHeader.value,
        data: salesTableData.value,
        filename: 'campaign_results'
      })
    }

    const getCampaignLists = () => {
      if (tabID.value === 'all') {
        getLists(`/v1/campaign/campaign-results`)
      } else {
        getLists(`/v1/campaign/campaign-results?status=${tabID.value}`)
      }
    }

    const getInitialData = () => {
      getCampaignLists()
    }

    const onRequest = props => {
      const { page, rowsPerPage } = props.pagination
      isFiltered.value = true
      pageNo.value = page
      limitPage.value = rowsPerPage

      getInitialData()
    }

    onBeforeMount(() => {
      getInitialData()

      tabs.value = tabs.value.map(item => {
        return {
          ...item,
          enabled: campaignType.results.includes(item.name)
        }
      })

      watch([dataLists, errorLists, loadingLists], () => {
        if (loadingLists.value) {
          salesTableData.value = []
        } else {
          if (dataLists?.value) {
            const allowedStatus = campaign
              .filter(item =>
                ['setup', 'active', 'completed'].includes(item.name)
              )
              .map(item => item.label)

            salesTableData.value = dataLists?.value
              .filter(item => allowedStatus.includes(item?.status))
              .sort((a, b) => (a.code < b.code ? -1 : 1))
              .map(item => ({
                id: item?.campaign_id,
                campaignCode: item?.code,
                campaignName: item?.name,
                campaignDuration: `${DATE.toFriendlyDate(
                  item?.date_from
                )} to ${DATE.toFriendlyDate(item?.date_to)}`,
                status: item?.status,
                reach: item?.reach,
                claimed: item?.claimants,
                unclaimed: item?.unclaimed,
                percentClaimed: (item?.claimants / item?.reach) * 100,
                percentUnclaimed: (item?.unclaimed / item?.reach) * 100,
                sales: item?.sales ?? 0,
                trxCount: item?.trx_count ?? 0,
                basketSize: item?.basket_size ?? 0,
                tierTag: item?.tier_tag === 'Y' ? true : false
              }))
          }
        }
      })
    })

    return {
      HELP,
      FORMAT,
      VALIDATION,
      getIconUrl,
      campaign,
      loadingLists,
      tabs,
      tabSelected,
      tabID,
      showModal,
      showViewDiscountModal,
      selectedData,
      limitPage,
      pageNo,
      totalCount,
      search,
      filterForm,
      statusOptions,
      salesTableHeader,
      salesTableData,
      hasData,
      campaignStatus,
      tierTag,
      onToggleTab,
      onToggleModal,
      onOpenViewDiscountModal,
      onCloseViewDiscountModal,
      getStatusData,
      onClearSearch,
      exportTable,
      onRequest
    }
  }
}
</script>

<style lang="scss" scoped>
ol {
  display: block;
  list-style: decimal;
  padding: 0 0 0 40px;

  li {
    list-style-position: outside;
    padding-left: 10px;
  }
}
</style>
